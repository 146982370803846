import { useState, useEffect, lazy, Suspense, ElementType } from "react";
import { useTranslation } from "react-i18next";

// Mock Hooks
const useAppDispatch = () => () => {};
const useAppSelector = (selector) => selector(mockState);
const mockState = {
  userLoginData: {
    privileges: [
      "DashboardScanSummary",
      "DashboardTopProducts",
      "DashboardProductList",
      "DashboardAwaitingAction",
      "DashboardProductRecentlyAdded",
    ],
  },
  dashboardIconsData: [
    { encIconId: "DASHBOARD", iconDescription: "Dashboard Information" },
  ],
};
const getUserLoginData = (state) => state.userLoginData;
const useGetIconDescription = () => ({
  iconDescriptionResponse: { output: mockState.dashboardIconsData },
});
const hasPrivileges = (privilege, userData) =>
  userData.privileges.includes(privilege);

// Mock Components
const AwaitingActionsContainer = () => <div>Awaiting Actions</div>;
const RecentProductContainer = () => <div>Recent Product</div>;
const CustomEditableTooltip = ({ text }) => <div>{text}</div>;
const CommonSkeleton = () => <div>Loading...</div>;

// Mock lazy-loaded components
const ScanSummaryContainer = () => <div>Scan Summary</div>;
const TopProducts = () => <div>Top Products</div>;
const ProductList = () => <div>Product List</div>;

const DashBoard = () => {
  const { t } = useTranslation("dashboard");

  const [dashboardInfo, setDashboardInfo] = useState("");

  const dispatch = useAppDispatch();
  const getLoginUserData = useAppSelector(getUserLoginData);

  const { iconDescriptionResponse } = useGetIconDescription();
  const dashboardAreaIconsData = useAppSelector(
    (state) => state.dashboardIconsData
  );

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="pb-4 border-b border-gray-300 mb-4">
        <h3 className="text-2xl font-semibold text-gray-800 flex items-center">
          <CustomEditableTooltip
            showIcon="faInfoCircle"
            text={dashboardInfo}
            moduleId={"DASHBOARD"}
          />
        </h3>
      </div>
      <div className="flex flex-wrap -mx-2">
        <div className="w-full lg:w-3/5 px-2 mb-4 lg:mb-0">
          {hasPrivileges("DashboardScanSummary", getLoginUserData) && (
            <div className="bg-white shadow-md rounded p-4 mb-4">
              <Suspense fallback={<CommonSkeleton />}>
                <ScanSummaryContainer />
              </Suspense>
            </div>
          )}
          {hasPrivileges("DashboardTopProducts", getLoginUserData) && (
            <div className="bg-white shadow-md rounded p-4 mb-4">
              <Suspense fallback={<CommonSkeleton />}>
                <TopProducts />
              </Suspense>
            </div>
          )}
          {hasPrivileges("DashboardProductList", getLoginUserData) && (
            <div className="bg-white shadow-md rounded p-4 mb-4 overflow-auto h-80">
              <Suspense fallback={<CommonSkeleton />}>
                <ProductList />
              </Suspense>
            </div>
          )}
        </div>
        <div className="w-full lg:w-2/5 px-2">
          {hasPrivileges("DashboardAwaitingAction", getLoginUserData) && (
            <div className="bg-white shadow-md rounded p-4 mb-4">
              <AwaitingActionsContainer />
            </div>
          )}
          {hasPrivileges("DashboardProductRecentlyAdded", getLoginUserData) && (
            <div className="bg-white shadow-md rounded p-4">
              <RecentProductContainer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
