import React from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import QrDetails from './pages/QrDetails';
import Dashboard from './pages/Dashboard';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Dashboard />} />
            <Route path="/:key" element={<QrDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App