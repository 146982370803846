
//1st change took place on 7th August-2024. Change name-v1.01
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";
import Header from "../components/Header";
import Footer from "../components/Footer";
import QrInfo from "../components/QrInfo";
import ReportModal from "../components/ReportModal";
import axios from "axios";
import ProductAuthentication from "../components/ProductAuthentication";

const QrDetails = () => {
  const { key } = useParams();
  const [qrData, setQrData] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [error, setError] = useState(null);
  const initialFetchDone = useRef(false);

  /////////////////////////////////////////////////////////////////////////////////////////////
  //Not necessary in new code. this code segment was to show the logo and label image in a modal
  //commented out in v1.01
  ///////////////////////////////////////////////////////////////////////////////////////////////
  // const handleClick = (image) => {
  //   if (!image) return;

  //   setLoadingImage(true);
  //   setSelectedImage(image);
  //   setShowModal(true);

  //   // Simulate image loading
  //   setTimeout(() => {
  //     setLoadingImage(false);
  //   }, 1000); // Adjust the timeout as needed
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (event.target === document.getElementById("modal")) {
  //       setShowModal(false);
  //     }
  //   };

  //   if (showModal) {
  //     document.addEventListener("click", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [showModal]);

  //this code segment is to show report modal when report_fake is checked. it is genrally set when a qr is checked more than 6 times
  // useEffect(() => {
  //   if (qrData && qrData.report_fake !== null && qrData.report_fake) {
  //     setShowReportModal(true);
  //   }
  // }, [qrData]);
  ////////////////////////////////////////////////////////////////////
  //// comment out ended
  ////////////////////////////////////////////////////////////////////



  ///////////////////////////////////////////////////////////////
  ////this segment added in v1.01
  ///////////////////////////////////////////////////////////////
  const fetchQrData = (lat, lon) => {
    fetch(`https://ax4-test.onrender.com/qr-updated/${key}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latitude: lat,
        longitude: lon,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setQrData(data);
        setIsCompleted(true);
      })
      .catch((error) => {
        console.error("Error sending location data:", error);
      });
  };

  const requestLocationUpdated = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          fetchQrData(position.coords.latitude, position.coords.longitude);
          setError(null);
        },
        (error) => {
          console.error("Error getting location:", error);
          setError(error.message);
          fetchLocationFromIPUpdated();
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setError("Geolocation is not supported by this browser.");
      fetchLocationFromIPUpdated();
    }
  };

  const fetchLocationFromIPUpdated = () => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((ipResponse) => {
        const ipAddress = ipResponse.data.ip;
        return axios.get(
          `https://api.ipgeolocation.io/ipgeo?apiKey=c3448732606b441d9fda734931ee304e&ip=${ipAddress}`
        );
      })
      .then((locationResponse) => {
        const { latitude, longitude } = locationResponse.data;
        setLatitude(latitude);
        setLongitude(longitude);
        fetchQrData(latitude, longitude);
      })
      .catch((err) => {
        console.error("Error fetching location from IP:", err);
        fetchQrData(null, null);
      });
  };

  useEffect(() => {
    requestLocationUpdated();
  }, [key]);

  ///////////////////////////////////////////////////////////////
  ////end of added segment
  ///////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////
  ////commented out in v1.01
  ///////////////////////////////////////////////////////////////
  // //this code segment is to get initial qr data using GET method
  // const fetchInitialQrData = () => {
  //   if (initialFetchDone.current) return;

  //   initialFetchDone.current = true;

  //   fetch(`https://ax4-test.onrender.com/qr/${key}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //       setQrData(data);
  //       setIsCompleted(true);

  //       // After showing the data, request location for the POST request
  //       requestLocation(data.decrypted_key, data.brand_id);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching QR data:", error);
  //       setShowReportModal(true);
  //       setIsCompleted(true);
  //     });
  // };

  // //this code segment is used to get detailed qr data using POST method. it posts the location and other data to backend
  // const fetchQrDataWithLocation = (lat, lon, decrypted_key, brand_id) => {
  //   fetch(`https://ax4-test.onrender.com/qr/${key}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       latitude: lat,
  //       longitude: lon,
  //       decrypted_key,
  //       brand_id,
  //     }),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Location data sent successfully");
  //       setQrData((prevData) => ({ ...prevData, ...data }));
  //       // If needed, handle the response from the POST request
  //     })
  //     .catch((error) => {
  //       console.error("Error sending location data:", error);
  //     });
  // };

  // //this code segment is called only if the location access not given by the user from below function named 'requestLocation'
  // const fetchLocationFromIP = (decrypted_key, brand_id) => {
  //   axios
  //     .get("https://api.ipify.org?format=json")
  //     .then((ipResponse) => {
  //       const ipAddress = ipResponse.data.ip;
  //       return axios.get(
  //         `https://api.ipgeolocation.io/ipgeo?apiKey=c3448732606b441d9fda734931ee304e&ip=${ipAddress}`
  //       );
  //     })
  //     .then((locationResponse) => {
  //       const { latitude, longitude } = locationResponse.data;
  //       setLatitude(latitude);
  //       setLongitude(longitude);
  //       fetchQrDataWithLocation(latitude, longitude, decrypted_key, brand_id);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching location from IP:", err);
  //       fetchQrDataWithLocation(null, null, decrypted_key, brand_id);
  //     });
  // };

  // //this code segment is to get the location. if location access not given it will get location from ip
  // const requestLocation = (decrypted_key, brand_id) => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //         fetchQrDataWithLocation(
  //           position.coords.latitude,
  //           position.coords.longitude,
  //           decrypted_key,
  //           brand_id
  //         );
  //         setError(null);
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error);
  //         setError(error.message);
  //         fetchLocationFromIP(decrypted_key, brand_id);
  //       },
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //         maximumAge: 0,
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //     setError("Geolocation is not supported by this browser.");
  //     fetchLocationFromIP(decrypted_key, brand_id);
  //   }
  // };

  // //this code is to get qr data from backend when page loads
  // useEffect(() => {
  //   fetchInitialQrData();
  // }, [key]);
  /////////////////////////////////////////////////////////////
  ////end of commented out
  /////////////////////////////////////////////////////////////

  //this code segment is to play audio if the same qr code is checked multiple times
  const startPlayingAudio = () => {
    const audio = new Audio("/audio/fake.mp3");
    let playCount = 0;

    const playAudio = () => {
      if (playCount < 2) {
        audio.play();
        playCount += 1;
      } else {
        audio.removeEventListener("ended", playAudio);
        setAudioPlaying(false);
      }
    };

    audio.addEventListener("ended", playAudio);
    setAudioPlaying(true);
    playAudio();
  };

  useEffect(() => {
    if (qrData && qrData.is_checked && !audioPlaying) {
      const playAudioHandler = () => {
        startPlayingAudio();
        document.removeEventListener("click", playAudioHandler);
      };

      document.addEventListener("click", playAudioHandler);
    }
  }, [qrData, audioPlaying]);

  return (
    <>
    <ProductAuthentication
    qrData={qrData}
    isCompleted={isCompleted}
    />
      {/* <Header /> */}
      {/* <QrInfo
        qrData={qrData}
        isCompleted={isCompleted}
        showModal={showModal}
        handleClick={handleClick}
        closeModal={closeModal}
        selectedImage={selectedImage}
        loadingImage={loadingImage}
        setShowReportModal={setShowReportModal}
      /> */}
      {/* <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        qrData={qrData}
        latitude={latitude}
        longitude={longitude}
      /> */}
      <Footer />
    </>
  );
};

export default QrDetails;
