import React from 'react';

const LoadingAnimation = () => {
    return (
        <div>
            <div className="border-t-4 border-slate-800 border-solid rounded-full animate-spin h-20 w-20"></div>
        </div>
    );
};

export default LoadingAnimation;